.card {
    margin-bottom:30px;

    -webkit-backface-visibility: hidden;

    &.service{
        background-color: transparent;
        text-align: center;
        padding:20px;
        display: block;
        color: $red;
        border: 3px solid $red;
        font-size: 15px;
        @include transition(all .2s linear);
        i {
            @extend .fa-3x;
            display: block;
            margin-bottom: 10px;
        }
        &:hover, &:focus, &.selected {
            background: $red;
            text-decoration: none;
            color: $white;
        }
        &.selected{
            background-color: $red !important;
        }
    }

    &.careers{
        background-color: transparent;
        text-align: left;
        padding:20px;
        display: block;

        border: 3px solid $lgrey;
        font-size: 15px;
        @include transition(all .2s linear);

        h2{
            a{
                color: $red;
            }
        }

        p{
            margin-bottom: 0;
        }

    }

    &.logos{
        text-align: center;
        font-size: 14px;
        color: $mdgrey;

        & picture{
            margin-bottom: 10px;
            /* -webkit-backface-visibility: hidden;
            @include transition(all .2s linear); */
        }

        /* @include respond-to('md'){
            &:hover, &:focus {
                picture{
                    transform: translateZ(0) scale(1.1) rotate(2deg);
                }
            }
        } */
    }

    &.work{
        font-size: 14px;
        color: $mdgrey;
        margin-bottom: 0;

        @include respond-to-max('xs'){
            margin-bottom: $bricks_xs_padd;
        }

        h3{
            @extend .h4;
            margin-bottom: 10px;
            > a{
                color: $red;
            }
            + p {
                color: $lgrey;
                margin-bottom: 20px;
            }
        }

        hr{
            margin: 15px 0 15px;
        }

        > .card-inner{
            background: $dgrey;
            padding: 20px;

            picture{
                @extend .img-thumbnail;
            }

            .description{
                padding: 10px 20px;
            }
        }

        .btn{
            /* @extend .btn-orange; */
        }
    }

    &.profile {
        color: $dgrey;
        background: transparent;
        h2, .profile-image {
            text-align: center;
            margin-bottom: 20px;
        }
    }
}

.content-secondary{
    .card{
        &.service{
            &:hover, &:focus {
                background-color: $red;
            }

            i{
                @extend .fa-2x;
                margin-bottom: 5px;
            }
        }
    }
}
