.case-study{
    .main-image{
        @extend .img-thumbnail;
        margin-bottom: 30px;

        &+h1{
            margin-top:0;
        }
    }
}
