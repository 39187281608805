article.panel, article.post {
    box-shadow: none;

    h2 {
        margin-top: 0;
        margin-bottom: 5px;
    }

    h1 + .meta{
        margin-top:5px;
    }

    & .panel {
        &-image {
            margin-bottom: 0;
        }
    }

    .meta, .info {
        @include respond-to('xxl') {
            font-size: 14px;
        }
        @include respond-to-max('xxl') {
            font-size: 12px;
        }
    }

    .meta{
        &::after{
            @extend .small-divider;
            margin:10px 0 0;
        }
    }

    picture {
        margin: 0 0 10px 0;
        @extend .img-thumbnail;
        /* img{
            -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
        } */
    }

    & + .pw-widget{
        margin-top: 40px;
    }
}

article {
    &.post {
        img {
            @extend .img-responsive;
        }

        h2 {
            margin-top: 30px;
            font-weight: 600;
            margin-bottom: 15px;
        }

        h2, h3, h4{
            &.overline{
                padding-top: 8px;
                border-top: 2px dotted #ccc;
                display: inline-block;
            }
        }
    }
}

.sharing{
    text-align: center;
}
