$breakpoints: (
    'xs'  : 480px,
    'sm'  : 767px,
    'md' : 992px,
    'lg'  : 1200px,
    'xl'  : 1600px,
    'xxl'  : 2500px
) !default;

// Bricks padding
$bricks_xs_padd: 15px;
$bricks_sm_padd: 15px;
$bricks_md_padd: 15px;
$bricks_lg_padd: 20px;
$bricks_xl_padd: 30px;
$bricks_xxl_padd: 40px;

$screen-xl: map-get($breakpoints, 'xl');
$screen-xl-min: ($screen-xl);

$screen-xxl: map-get($breakpoints, 'xxl');
$screen-xxl-min: ($screen-xxl);

$grid-columns: 24 !default;
