.bricks{
    @include respond-to('xs'){
        margin-left: -$bricks_xs_padd;
        margin-right: -$bricks_xs_padd;
    }
    @include respond-to('sm'){
        margin-left: -$bricks_sm_padd;
        margin-right: -$bricks_sm_padd;
    }
    @include respond-to('md'){
        margin-left: -$bricks_md_padd;
        margin-right: -$bricks_md_padd;
    }
    @include respond-to('lg'){
        margin-left: -$bricks_lg_padd;
        margin-right: -$bricks_lg_padd;
    }
    @include respond-to('xl'){
        margin-left: -$bricks_xl_padd;
        margin-right: -$bricks_xl_padd;
    }
    @include respond-to('xxl'){
        margin-left: -$bricks_xxl_padd;
        margin-right: -$bricks_xxl_padd;
    }

    .brick-item{
        width: 100%;
        padding: $bricks_xs_padd;

        @include respond-to('xs'){
            padding: $bricks_xs_padd;
        }
        @include respond-to('sm'){
            width: 50%;
            padding: $bricks_sm_padd;
        }
        @include respond-to('md'){
            padding: $bricks_md_padd;
        }
        @include respond-to('lg'){
            padding: $bricks_lg_padd;
        }
        @include respond-to('xl'){
            width: 33.33333%;
            padding: $bricks_xl_padd;
        }
        @include respond-to('xxl'){
            width: 25%;
            padding: $bricks_xxl_padd;
        }
    }
}
