.table-display {
    display: table;
    width: 100%;
    height: 100%;

    > .vert-middle {
        display: table-cell;
        vertical-align: middle;
    }
}
