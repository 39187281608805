.form-group {
    select {
    }
    textarea {
        height: 180px;
    }
    label {
        &.error {
            color: darken($alert-red, 20%);
            border: 1px solid darken($alert-red, 20%);
            background-color: rgba($alert-red, 0.75);
            border-top: none;
            padding: 10px;
            width: 100%;
        }
    }
    .form-control {
        &:focus {
            border-color: $red;
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(240, 127, 22, 0.6);
        }
    }
}

input[name="txt_address"] {
    display: none;
}

.content-secondary {
    padding-top: 20px;
    @include respond-to('lg') {
        padding-top: 29px;
    }
    .form-contact {
        //margin-bottom: 10px;
        .btn {
            margin-bottom: 20px;
        }
        .control-label {
            color: $white;
            .required {
                color: $red;
                margin-left: 5px;
            }

            @extend .col-lg-6;

            @include respond-to('lg') {
                width: 100%;
            }

            @include respond-to('xl') {
                width: 25%;
            }
        }

        .form-control {
            border: 0;
            color: #fff;
            background-color: lighten($dgrey, 10%);
            margin-bottom: 20px;

            @extend .col-lg-18;

            @include respond-to('lg') {
                width: 100%;
            }

            @include respond-to('xl') {
                width: 75%;
            }
        }

        .form-control::placeholder {
            color: $lgrey;
        }
        .control-label {
            padding: 0;
        }
    }
}
