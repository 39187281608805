.content-primary{
    blockquote {
        margin: 20px 0;
        border: none;
        text-align: center;

        color: $red;

        @include font-size(18);

        @include respond-to-max('xl'){
            @include font-size(14);
        }

        > cite{
            display: block;
        }

        &::before, &::after {
            @extend .small-divider;
        }

        &::after{
            margin-top: 12px !important;
        }

        cite{
            @include font-size(11);
            color: $dgrey;
            text-transform: capitalize;
        }

        footer{
            @include font-size(11);

            &:before{
                content: '\02dc';
                @include font-size(25);
                @include line-height(4);
                vertical-align: bottom;
                margin-left: 10px;
            }
        }
    }
}

