//base colours
$white: #ffffff;
$black: #000000;
$jet: #222222;

// Greys
$dgrey: #2d2d2d;
$mdgrey: #515151;
$lgrey: #f5f5f5;

// Core Colours
$header-brown: #e2dad0;
$brown: #442a19;
$red: #e04538;
$deep-red: #c20101;

// More colours
$alert-red: $red;
$dark-blue: #00aeef;
$light-blue: #87d7f5;

$brand-facebook: #133783;
$brand-twitter: #55acee;
$brand-instagram: #3f729b;

$link-color: $red !default;

$headings-color: $red;

/* Gradients */

$gradient-logo: (
    0%: $red,
    80%: $red,
    100%: darken($deep-red, 0%)
);

.header-brown {
    color: $header-brown;
}

.brown {
    color: $brown;
}

.red {
    color: $red;
}

.deep-red {
    color: $red;
}

.lgrey{
    color: lighten($dgrey, 30%);
}

.dgrey{
    color: $dgrey;
}
