/**
 * Anything that effects all layouts goes in this file.
 */
body {
    background-color: $lgrey;

    > .nav-container{
        border-top: 5px solid $red;
        background-color: $header-brown;
    }

    > .navbar-collapse{
        background: #fff;

        + .cta-container{
            padding-top: 50px;
            background-color: $white;

            .cta-bg-wrap{
                background-color: $header-brown;
            }
        }
    }

    > .search-collapse{
        max-height: 100px;
        background-color: $white;
    }

    > .cta-container{
        background-color: $header-brown;

        &.brown{
            background-color: $brown;
        }
    }

    > .footer-container{
        background-color: $header-brown;
    }

    > .basefooter-container{
        background-color: $white;
    }

    > .content-primary{
        @include respond-to('sm'){
            padding-top: 40px;
            padding-bottom: 50px;
        }
    }
}

.page-header{
    padding-top: 60px;
    padding-bottom: 60px;
    margin:0;
    border:0;

    &.white{
        background-color: $white;
    }

    &.search{
        margin-bottom: 30px;
    }
}

.logo{
    max-width: 100%;
    height: auto;
}

.logo-panel{
    position: absolute;
    top:0;
    left:0;
    z-index: 5000;
    background-color: $red;
    display: block;
    height: auto;
    padding:0 20px 20px;
    text-align: center;
    margin-left: 15px;
    margin-right: 15px;

    @include respond-to-max('sm') {
        padding-top: 10px;
    }

    @include border-radius(0 0 10px 10px);
    @include gradient(-90deg, $gradient-logo);

    a{
        display: block;
        > img{
            max-width: 100%;
            height: auto;
        }
    }

    .established{
        font-family: $font-family-serif;
        font-weight: 100;
        text-align: center;
        color: $white;
        font-size: 14px;

        padding-top: 5px;
        padding-bottom: 10px;
        border-bottom: 2px dashed #cd1c16;

        .logo{
            img{
                vertical-align: baseline;
                display: inline-block;
                max-width: 30%;
                height: auto;
                margin-right: 9%;
            }
        }
    }

    .navbar-brand {
        height: auto;
        float: none;
        @include hide-text();

        @media (max-width: 480px){
            padding:0;
        }
    }
}

.banner-container{
    color: $white;

    .container{
        text-align: center;
        > .content{
            float: none;
            margin: 0 auto;
        }
    }
}

.cta-container{
    &:not(.brown){
        border-top: 1px solid $white;
    }
    &.brown{
        border-bottom: 1px solid $white;
    }
    .row{

        > div{
            padding:0;
            border-right: 1px solid $white;
        }
        > div:last-child{
            border-right:0;
        }
        @include respond-to('sm'){
            margin:0;
        }
    }
    .btn{
        border:0;
        @include border-radius(0);
        background: $brown;

        @include font-size(13);
        padding-top: 10px;
        padding-bottom: 10px;
        color: $white;

        i{
            // display: block;
            top:0;
            vertical-align: middle;
            margin-top: -2px;
            @include font-size(18);
        }

        &.active{
            background-color: $lgrey;
            color: $red;
            box-shadow: none;
            border-color: $lgrey;
            text-decoration: none !important;

            &:hover, &:focus{
                background-color: $lgrey;
                color: $red;
                box-shadow: none;
                border-color: $lgrey;
                text-decoration: none !important;
            }

            &:before{
                background: $lgrey;
            }
        }

        .type-label{
            margin-left: 7px;
        }

        @include respond-to-max('sm'){
            .type-label{
                display: none;
            }
        }
    }
}

.global-search{
    border: 2px solid $red;
    @include border-radius(5px)
    background: $white;// max-width: 70%;
    margin: 25px 0;

    + div{
        line-height: 100px;
        text-align: center;
    }

    #searchBar{
        vertical-align: middle !important;
        margin: 0 -10px;
        padding:0;
        width: 100%;
        padding-left: 10px;

        &:focus{
            box-shadow: none;
        }
    }

    .form-inline{
        border: 2px solid $white;
    }

    .form-group{
        display: block;
        margin-bottom:0;

        > div{
            &:first-child{
                padding-right:0;
            }
            &:last-child{
                padding-left:0;
                padding-right:0;
            }
        }

        .twitter-typeahead{
            display: block !important;

            + button{
                float: right;
            }
        }
    }

    .form-control{
        margin:0;
        border:0;
        display: block;
        padding-left: 20px;
        font-family: $font-family-serif;
        font-weight: 300;
    }

    .btn{
        @include border-radius(0);
        border:0;
        padding-top: 8px;
        padding-bottom: 8px;

        &:hover{
            background-color: $deep-red !important;
            color: $white !important;
        }
    }
}

.force-text-wrap{
    white-space: pre-wrap; /* css-3 */
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
}

.cookie-notification {
    background-color: $alert-red;
    color: $white;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 28px;
    z-index: 10000;
    .close {
        position: absolute;
        right: 10px;
        top: 10px;
        color: $white;
        opacity: .5;
        &:hover, &:focus {
            opacity: 1;
        }
    }
    button {
    }
}

.footer-container{
    padding: 30px 0;
}

.basefooter-container{
    p, div{
        @include font-size(10);
        margin-bottom:0;
    }

    .copyright{
        > div{
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }
}
