/**
 * Mixins and functions
 */
@import "utility/mixins";


/**
 * Settings for our theme, and overrides for vendor packages e.g. bootstrap/font-awesome
 */
@import "settings/grid";
@import "settings/colours";
@import "settings/bootstrap";
@import "settings/typography";
@import "settings/slick-carousel";

/**
 * Vendor scss needs to be loaded before our
 * layouts otherwise it will overwrite our styling.
 */
@import "components/bootstrap";
@import "components/font-awesome";
@import "components/slick-carousel";

/**
 * Bring our layouts into the mix
 */
@import "layout/global";
@import "layout/two-col";
@import "layout/three-col";


/**
 * All the different components to our theme
 * WIP in terms of separation
 */
@import "components/animations";
@import "components/typography";
@import "components/pagination";
@import "components/forms";
@import "components/gmaps";
@import "components/buttons";
@import "components/cards";
@import "components/panels";
@import "components/menu";
@import "components/lists";
@import "components/heart";
@import "components/blockquotes";
@import "components/prism";
@import "components/headshots";
@import "components/table";
@import "components/carousels";

// October Plugins
@import "plugins/gallery";
@import "plugins/social";
@import "plugins/case-studies";
@import "plugins/ajax-loader";
@import "plugins/blog";
@import "plugins/carriage";
