// As is often the case, some devices/browsers need additional code to get CSS to work
// in the most desired way. These mixins are used to quickly drop in hacks for each element
// Find out more here: https://github.com/IanLunn/Hover/wiki/Hacks-Explained

@mixin hardwareAccel() {
	// Improve performance on mobile/tablet devices
	@include prefixed(transform, translateZ(0));
}

@mixin improveAntiAlias() {
	// Improve aliasing on mobile/tablet devices
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

@mixin fontSmooth() {
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-moz-osx-font-smoothing: grayscale;
}

@mixin forceBlockLevel() {
	// Transforms need to be block-level to work
	display: inline-block;
	vertical-align: middle;
}

@mixin hacks() {
	@include forceBlockLevel();
	@include hardwareAccel();
	@include improveAntiAlias();
	@include fontSmooth();
}
