/**
 * Anything that affects both all templates should go in global
 */
.full-width {
    .container-fluid {
        background: none;
        @include crossbrowser(#fff, .95);
    }
    .content-primary {
        background: none;
    }
}
