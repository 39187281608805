.banner-container{
    background-color: $brown;
    position: relative;
}

.header-slider{
    width: 100%;

    .slick-track{
        height: 400px;
    }

    .slick-slide{
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
    }

    &.small{
        .slick-track{
            height: 150px;
        }
    }
}

.text-slider{
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;

    .slick-track{
        height: 400px;
    }

    .slick-slide{
        background-color: rgba(68, 42, 25, 0.9);
        text-align: center;
        position: relative;

        .container::before {
            content: '';
            display: inline-block;
            height: 100%;
            vertical-align: middle;
        }
        .container{
            position: absolute;
            margin: 0 auto;
            float: none;
            display: inline-block;
            left: 50%;
            top:50%;
            transform: translate(-50%, -50%);
        }
    }

    &.small{
        .slick-track{
            height: 150px;
        }
    }
}

.slick-slider{
    &:hover{
        .slick-prev, .slick-next{
            opacity: 1;
        }
    }
}

.slick-prev, .slick-next{
    width: 50px;
    height: 50px;
    opacity:0;
    transition: linear all .3s;
    &:before{
        font-family: "FontAwesome";
        @include font-size(50);
        line-height: 50px;
    }
}

.slick-prev{
    left: 30px;
    z-index: 5000;

    &:before{
        content: "";
    }
}

.slick-next{
    right: 30px;
    z-index: 5000;

    &:before{
        content: "";
    }
}

.item-image-slider{
    + p{
        background: $lgrey;
        padding-top: 15px;
        padding-bottom: 20px;
        @include font-size(11);
    }

    &:not(.only_one){
        .slick-slide{
            border: 2px solid $lgrey;
            text-align: center;
            height: 100%;
            > img{
                position: relative;
                top: 50%;
                margin: 0 auto;
                transform: translateY(-50%);
            }
        }
    }

    .slick-prev, .slick-next{
        background: $white;
        &:before{
            color: $red;
            line-height: 50px;
        }
    }

    .slick-dots{
        background: $lgrey;
        bottom: -32px;
        padding-top: 10px;
        > li{
            & > button{
                &:before{
                    color: $dgrey;
                    font-size: 16px;
                }
            }

            &.slick-active{
               & > button{
                    &:before{
                        color: $red;
                        font-size: 16px;
                        opacity: 1 !important;
                    }
                }
            }
        }
    }
}


