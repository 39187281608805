.tt-menu{
    background: $white;
    min-width: 300px;
    width: 100%;

    .tt-suggestion{
        border-bottom: 1px solid $lgrey;
        padding: 5px 15px;

        &:hover{
            background-color: $brand-primary;
            cursor: pointer;
        }
    }
    .tt-highlight{
        font-weight: bold;
    }
}

.nav-container{
    @include respond-to-max('sm') {
        .navbar-holding-container{
            padding-left:0;
        }
    }
}

@include respond-to('sm') {

    .search-collapse.collapse {
        display: block !important;
        height: auto !important;
        padding: 0;
        overflow: visible !important;
    }
}

.navbar-default {
    border: none;
    min-height: auto;
    margin-bottom: 0;
    .navbar-toggle {
        color: $mdgrey;
        border: 1px solid $mdgrey;
        padding-top: 3px;
        padding-bottom: 3px;
        float: right;
        text-transform: uppercase;

        &:focus{
            color: $white;
            border-color: $red;
            & .iconbars{
                > span{
                    background-color: $white;
                }
                + .text{
                    color: $white;
                }
            }
        }

        &.navbar-search{
            margin-right: 8px;
            color: $mdgrey;

            &:focus{
                color: $white;
                border-color: $red;
            }
        }

        & .iconbars{
            float: left;
            display: inline-block;
            vertical-align: middle;
            margin-top: 6px;
            margin-right: 5px;
            > span{
                background-color: $dgrey;
            }
            + .text{
                display: inline-block;
                color: darken($dgrey, 10%);
                @include font-size(10)
            }
        }
        @include respond-to-max('sm') {
            margin-top:0;
            margin-right: 0;
        }
        &:hover, &:focus  {
            background-color: $red;
        }
    }
}
.navbar-header {
    @include respond-to-max('sm') {
        padding: 8px 5px 0;
    }
}

.navbar-collapse {
    padding: 0;
    max-height: none;
}
.navbar-nav {
    width: 100%;
    > li{
        @include font-size(12);
        > a{
            color: $brown;
            width: inherit;
            padding-top: 13px;
            padding-bottom: 13px;
            color: $red;

            &:hover{
                cursor: pointer;
            }
        }

        & + li:before {
            content: " | ";
            display: inline-block;
            float: left;
            line-height: 50px;
        }
    }
}

.footer-menu{
    & li.active{
        & a{
            font-weight: bold;
        }
    }
}

.mobile-menu-social-icons{
    text-align: center;
    margin-top: 30px;
    a {
        color: $red;
    }

}
