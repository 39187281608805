.fa-ul{
    margin-bottom:20px;
    li {
        i {
            padding: 8px 0;
        }
        padding:5px 0;
        a {
            color: $red;
        }
    }
    li.active{
        > a {
            font-weight: bold;
        }
    }

    & .meta{
        display: block;
        color: $lgrey;
        font-size: 12px;
    }
}

