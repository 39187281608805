@import url(https://fonts.googleapis.com/css?family=Roboto+Slab:100,300|Roboto:100,300);

$font-family-sans-serif:  "Roboto", Arial, sans-serif !default;
$font-family-serif:       "Roboto Slab", Georgia, "Times New Roman", Times, serif !default;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace !default;
$font-family-base:        $font-family-sans-serif !default;

//Bootstrap overrides
$font-size-base: 16px !default;

$font-size-h1:            floor(($font-size-base * 2.5)) !default;
$font-size-h2:            floor(($font-size-base * 1.6)) !default;
$font-size-h3:            ceil(($font-size-base * 1.4)) !default;
$font-size-h4:            ceil(($font-size-base * 1.2)) !default;
$font-size-h5:            $font-size-base !default;
$font-size-h6:            ceil(($font-size-base * 0.85)) !default;

//** Unit-less `line-height` for use in components like buttons.
$line-height-base:        1.558571429 !default; // 20/14
$line-height-headings:        1.328571429 !default; // 20/14
