strong{
    font-weight: 300;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    font-weight: 100;
}

h1, .h1{
    color: $brown;
    font-weight: 300;
    font-family: $font-family-serif;
    @include line-height(40)
}

h2, .h2{
    color: $red;
    font-weight: 300;
    @include line-height(27)
}

.intro{
    color: $red;
    font-weight: 300;
    @extend .h4;
}

h3, .h3{

}

h4, .h4{
    font-weight: 300;
    @include line-height(22);
}

h5, .h5{
    font-weight: 300;
}

h6, .h6{
    font-weight: 300;
}

p + h3{
    font-weight: 300;
}

.navbar{
    font-family: $font-family-serif;
    font-weight: 100;
}

.banner-container{
    h1, .h1, p{
        color: $white;
        font-weight: 100;
    }
    h1, .h1{
        margin-bottom: 30px;
    }
    p{
        @include line-height(20);
        margin-bottom: 20px;
    }
}

.page-header{
    h1, .h1,
    h2, .h2,
    h3, .h3,
    h4, .h4{
        margin-top:0;
    }
}

.content-primary{
    h1, .h1{
        margin-top:0;
    }
}
