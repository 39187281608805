@mixin respond-to($breakpoint) {
    // Retrieves the value from the key
    $value: map-get($breakpoints, $breakpoint);

    // If the key exists in the map
    @if $value != null {
        // Prints a media query based on the value
        @media (min-width: $value) {
            @content;
        }
    }

        // If the key doesn't exist in the map
    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Please make sure it is defined in `$breakpoints` map.";
    }
}

@mixin respond-to-max($breakpoint) {
    // Retrieves the value from the key
    $value: map-get($breakpoints, $breakpoint);

    // If the key exists in the map
    @if $value != null {
        // Prints a media query based on the value
        @media (max-width: $value) {
            @content;
        }
    }

        // If the key doesn't exist in the map
    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Please make sure it is defined in `$breakpoints` map.";
    }
}

@mixin respond-between($breakpoint1, $breakpoint2) {
    // Retrieves the value from the key
    $breakpoint-min: map-get($breakpoints, $breakpoint1);
    $breakpoint-max: map-get($breakpoints, $breakpoint2);

    // If the key exists in the map
    @if $breakpoint-min != null and $breakpoint-max != null {
        // Prints a media query based on the value
        @media (min-width: $breakpoint-min) and (max-width: $breakpoint-max) {
            @content;
        }
    }

        // If the key doesn't exist in the map
    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Please make sure it is defined in `$breakpoints` map.";
    }
}

@mixin hide-text() {
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
}

@mixin btn-colors($color, $text-color, $solid: false) {
    @if($solid) {
        background-color:$color;
        color: $text-color;
    } @else {
        color: $color;
        background-color:transparent;
    }

    border-color: $color;

    &:before{
        background-color: $color;
    }
    &:hover, &:focus {
        background-color: $color;
        border-color: $color;
        color: $text-color;
    }
}

@mixin btn-colors-solid ($background, $text-color) {
    background: $background;
    color: $text-color;
    overflow: hidden;

    &:before {
        background-color: transparent;
        color: $background;
    }

    &:hover, &:focus {
        background-color: transparent;
        color: $background;

        &:before {
            background-color: transparent;
            color: $text-color;
        }
    }
}

@mixin opacity($opacity) {
    opacity: $opacity;
    $opacity-ie: $opacity * 100;
    filter: alpha(opacity=$opacity-ie); //IE8
}


%clearfix {
    *zoom: 1;
    &:before, &:after {
        content: " ";
        display: table;
    }
    &:after {
        clear: both;
    }
}

@mixin transition($args...) {
    -webkit-transition: $args;
    -moz-transition: $args;
    -ms-transition: $args;
    -o-transition: $args;
    transition: $args;
}


// generic transform
@mixin transform($transforms) {
    -moz-transform: $transforms;
    -o-transform: $transforms;
    -ms-transform: $transforms;
    -webkit-transform: $transforms;
    transform: $transforms;
}
// rotate
@mixin rotate ($deg) {
    @include transform(rotate(#{$deg}deg));
}

// scale
@mixin scale($scale) {
    @include transform(scale($scale));
}
// translate
@mixin translate ($x, $y) {
    @include transform(translate($x, $y));
}
// skew
@mixin skew ($x, $y) {
    @include transform(skew(#{$x}deg, #{$y}deg));
}
//transform origin
@mixin transform-origin ($origin) {
    moz-transform-origin: $origin;
    -o-transform-origin: $origin;
    -ms-transform-origin: $origin;
    -webkit-transform-origin: $origin;
    transform-origin: $origin;
}


//define mixin
@mixin crossbrowser($color,$alpha){
    $rgba: rgba(red($color),green($color),blue($color),$alpha);
    $argb: argb($rgba);
    background-color: $color;
    background-color: $rgba;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$argb, endColorstr=$argb);
    -ms-filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$argb, endColorstr=$argb);
}


// @include gradient(gradient--concat($gradient--concat));
@mixin gradient($gradient-degrees, $gradient--concat, $bg:true) {
    $ie_variables: gradient--ie($gradient--concat);
    $start-color: map-get($ie_variables, 'start');
    $finish-color: map-get($ie_variables, 'finish');

    @if $bg == false {
        background-color: transparent; /* Fallback */
    } @else {
        background-color: mix($start-color, $finish-color); /* Fallback */
    }
    background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, from($start-color), to($finish-color));
    background-image: -webkit-linear-gradient($gradient-degrees, gradient--concat($gradient--concat));
    background-image:    -moz-linear-gradient($gradient-degrees, gradient--concat($gradient--concat));
    background-image:     -ms-linear-gradient($gradient-degrees, gradient--concat($gradient--concat));
    background-image:      -o-linear-gradient($gradient-degrees, gradient--concat($gradient--concat));
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorStr='#{$start-color}', EndColorStr='#{$finish-color}')";
}


// Concat :: Gradient Mixin
// Concatinate the gradient map properties and values into structured CSS
@function gradient--concat($gradient--concat){
    $return-new: '';

    @each $position, $color in $gradient--concat {
        $return: $color + ' ' + $position + gradient--position($position);
        $return-new: $return-new + '' + $return;
    }

    @return unquote($return-new);
}

// Position :: Gradient Mixin
// Add commas to the end of every property concationation, except the last one.
@function gradient--position($position){
    @if ( $position != 100%) {
        @return ",";
    }
    @else {
        @return "";
    }
}

// Internet Explore Fallback
// Extract the first and last color variables for "-ms-filter"
@function gradient--ie($gradient--concat){
    $start_map: '';
    $end_map: '';

    @each $position, $color in $gradient--concat {
        @if ( $position == 0%) {
            $start_map: (
                'start': $color
            )
        }
        @if ( $position == 100%) {
            $end_map: (
                'finish': $color
            )
        }
    }

    @return map-merge($start_map, $end_map)

}

@mixin font-size($sizeValue: 12 ){
    font-size: $sizeValue + px; //fallback for old browsers
    font-size: (0.125 * $sizeValue) + rem;
}

@mixin line-height($heightValue: 12 ){
    line-height: $heightValue + px; //fallback for old browsers
    line-height: (0.125 * $heightValue) + rem;
}

@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    border-radius: $radius;
    background-clip: padding-box;  /* stops bg color from leaking outside the border: */
}

@mixin center-block {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

@mixin text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
