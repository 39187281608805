
.heart {
    position: relative;
    width: 50px;
    height: 50px;
    display: inline-block;
    vertical-align: middle;
    text-indent: -90000px;

    &-sm{
        width: 35px;
        height: 35px;
        margin-top: -5px;
    }
}
.heart:before,
.heart::before,
.heart:after {
    background: $red;
    position: absolute;
    content: "";
    left: 0;
    top: 12%;
    width: 50%;
    height: 80%;

    border-radius: 50% 50% 50% 0;
    transform: translate(100%, 0) rotate(-45deg);
    transform-origin: 0 100%;
}
.heart:after {
    right: 0;
    left: auto;
    border-radius: 50% 50% 0 50%;
    transform: translate(-100%, 0) rotate(45deg);
    transform-origin: 100% 100%;
}

.love-message{
    margin: 0;
    > .btn{
        margin-top: 8px;
    }

    &::before {
        @extend .small-divider;
    }
}
