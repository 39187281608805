.small-divider{
    width: 30px;
    height: 3px;
    content: " ";
    background-color: $lgrey;
    display: block;
    margin: 0 auto;
    margin-bottom: 10px;
}

.social-feed {

    blockquote{
        margin-bottom:0;
        text-align: center;
        border:0;

        overflow: hidden;

        a{
            @extend .force-text-wrap;
            text-overflow: ellipsis;
        }

        /* &::before, &::after {
            @extend .small-divider;
        } */

        &::after{
            margin-top: 10px;
        }
    }

    .feed-item{
        margin-bottom: 30px;
        position: relative;

        blockquote{
            color: $dgrey;
        }

        > .social-list{
            li {
                &.twitter {
                    .fa{
                        background-color: $brand-twitter;
                        color: #fff;
                    }
                }
                &.facebook {
                    .fa {
                        background-color: $brand-facebook;
                        color: #fff;
                    }
                }
                &.instagram {
                    .fa {
                        background-color: $brand-instagram;
                        color: #fff;
                    }
                }
            }
            padding: 0 10px;
            margin:0;
            text-align: center;

            & a{
                color: $dgrey;
                font-size: 12px;
            }

            & i{
                margin-right: 5px;
                font-size: 20px;
                vertical-align: middle;
                margin-top: -2px;
            }

            .fa{
                width: 25px;
                height: 25px;
                text-align: center;
                line-height: 25px;
                margin-bottom: -2px;
            }
        }
    }

    &.colourful{
        blockquote{
            color: $lgrey;

            &::before, &::after {
                background-color: lighten($dgrey, 10%);
            }
        }

        .social-list{
            & a{
                color: $lgrey;
            }
        }

        .social-post-url[href^="http"]{
            color: $red;
        }

        &.facebook, &.twitter, &.instagram{
            > .social-list{
                & a:hover{
                    text-decoration: none;
                }
            }
        }
    }
}

.social-icon-list{
    li{
        margin-right: 5px;
        &:last-child{
            margin-right:0;
        }
        span{
            text-indent: -1000000px;
            white-space: nowrap;
            overflow: hidden;
            display: inline-block;
        }

        i{
            @extend .fa-2x;
        }
    }
}

.panel-contact {
    margin-bottom: 20px;
    .social-icon-list {
        li {
            margin-right:0;
            a {
                font-size: .8em;
                color: $red;
            }
        }
    }
}


#socialPosts:not(.sidebar-output) {
    blockquote {
        margin-top: 0;
        &:before, &:after {
            display: none;
        }
    }
    .grid-sizer, .feed-item {
        width:100%;
        margin: 10px;
        @include respond-to ('sm') {
            width:calc( 50% - 20px);
        }

        @include respond-to ('md') {
            width:calc( 33% - 20px);
        }
        @include respond-to ('lg') {
            width:calc( 33% - 20px);
        }
        @include respond-to ('xl') {
            margin: 20px;
            width:calc( 25% - 40px);
        }
    }

    .feed-item {
        padding-bottom: 20px;
        background-color: $dgrey;
        blockquote{
            color: $white;
            @include font-size(15);
        }
        .social-list a{ color: $white; }

        //border: 1px solid #000;
    }
    + .more-btn #socialPostsMoreBtn {
        margin-top: 10px;
    }
}

