.headshots{
    &.swing {
        @keyframes swinging{
            0%{transform: rotate(0deg);}
            25%{transform: rotate(10deg);}
            50%{transform: rotate(-10deg)}
            75%{transform: rotate(10deg);}
            100%{transform: rotate(0deg);}
        }
        transform-origin: 55% -15%;
        animation: swinging 3.5s ease-in-out forwards infinite;
    }

    &.pull-right{
        margin-left: 30px;
        margin-bottom: 30px;

        @include respond-to-max('sm'){
            margin-left:0;
        }
    }

    &.marg-top{
        margin-top: 30px;
    }

    width: 100%;

    background: $dgrey;
    padding: 10px 10px 0;
    z-index: 10;
    text-align: center;

    @include respond-to-max('sm'){
        float: none !important;
        margin:0;
        clear: both;
    }

    @include respond-to('sm'){
        max-width: 250px;
    }

    position: relative;

    &::before{
        width: 10px;
        height: 10px;
        display: block;
        content: " ";
        position: absolute;
        left: 50%;
        top:-30px;
        border-radius: 100%;
        transform: translate(-50%, 0);
        background: $dgrey;
        box-shadow: 1px 0 3px rgba(0,0,0,.4);
    }

    &::after{
        width: 52px;
        height: 52px;
        display: block;
        content: " ";
        position: absolute;
        left: 50%;
        top:-20px;
        border-radius: 20%;
        border: 1px solid $dgrey;
        z-index: -1;
        transform: translate(-50%, 0) rotate(45deg);
    }

    img{
        filter: grayscale(100%) sepia(50%);
        margin: 0 auto;
    }

    span{
        text-align: center;
        display: block;
        color: $lgrey;
        padding: 10px 0;
        font-size: 12px;
    }
}
