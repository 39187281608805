.panel {
    & .panel-wrapper{
        background: $white;
        padding: 20px;
    }

    &.item{
        .panel-wrapper{
            padding: 10px 20px 20px;
        }
        .panel-icon{
            margin: 0 auto;
            z-index: 400;
            padding: 8px 20px;
            margin: 0 -20px 0;
            border-bottom: 1px solid #fff;

            i{
                vertical-align: middle;
                float: right;
                @include font-size(17);
            }
            .type-label{
                font-family: $font-family-serif;
                text-transform: uppercase;
                @include font-size(11);
            }
        }
        &:hover{
            // .panel-image{
            //     transition: linear all .3s;

            //     .additional{
            //         display: none;
            //     }

            //     transform: scale(1.1);
            // }
        }
        .panel-image{
            background-color: $white;
            height: 150px;
            display: block;
            background-position: center center;
            background-size: cover;
            margin: 0 -20px 10px;
            padding: 5px;
            position: relative;

            .additional{
                position: absolute;
                bottom:0;
                right:0;
                margin: 5px;
                padding: 5px;
                display: block;
                background: $white;

                @include font-size(11);

                > strong{
                    font-weight: 300;
                }
            }
        }

        .panel-foot{
            padding: 0 20px 20px;
            background: $white;
        }
        h3{
            margin-top: 4px;
            font-family: $font-family-serif;
            color: $brown;
        }
        p{
            &:last-child{
                margin-bottom:0;
            }
        }
    }

    &.item-details{
        h3{
            color: $brown;
            font-family: $font-family-serif;
        }

        .panel-wrapper{
            padding: 30px;
            > :first-child{
                margin-top:0;
            }
        }
    }
}
