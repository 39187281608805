$btn-border-radius-base:         0 !default;
$btn-border-radius-large:        0 !default;
$btn-border-radius-small:        0 !default;
$padding-sm: 10px;
$padding-md: 20px;
$padding-lg: 30px;

$border-radius-base:        0 !default;
$border-radius-lg:       15px !default;
$border-radius-md:       10px !default;
$border-radius-sm:       5px !default;

$brand-primary: $red !default;

$btn-default-color: $light-blue !default;
$btn-default-bg: #fff !default;
$btn-default-border: $light-blue !default;

$list-group-border-radius: 0 !default;

/* Navbar */
$navbar-height:                    50px !default;
$navbar-border-radius:             $border-radius-base !default;
$navbar-collapse-max-height:       340px !default;

$navbar-default-color:             #fff !default;
$navbar-default-bg:                transparent !default;
$navbar-default-border:            0 !default;

/* Panels */
$panel-bg: transparent !default;
$input-border-focus:             $red !default;


// Navbar links
$navbar-default-link-color:                $brown !default;
$navbar-default-link-hover-color:          $red !default;
$navbar-default-link-hover-bg:             transparent !default;
$navbar-default-link-active-color:         #555 !default;
$navbar-default-link-active-bg:            darken($navbar-default-bg, 6.5%) !default;
$navbar-default-link-disabled-color:       #ccc !default;
$navbar-default-link-disabled-bg:          transparent !default;
