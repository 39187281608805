@import "../vendor/hover/options";
@import "../vendor/hover/mixins";
@import "../vendor/hover/hacks";
@import "../vendor/hover/effects/background-transitions/sweep-to-left";
// @import "../vendor/hover/effects/background-transitions/sweep-to-right";
// @import "../vendor/hover/effects/background-transitions/radial-out";

&.btn {
    @include sweep-to-left();
    transition: all .3s ease-in-out;
    border-width: 2px;
    @include font-size(14);
    @include border-radius(5px);

    text-transform: uppercase;
    font-family: $font-family-serif;
    font-weight: 100;

    display: block;

    &-primary {
        @include btn-colors($red, $white);
        &.btn-solid {
            @include btn-colors-solid ($red, $white);
        }
    }
    &-default {
        background-color: transparent;
    }
    &-white {
        @include btn-colors($white, $dgrey);
        &.solid {
            @include btn-colors-solid ($white, $dgrey);
        }
    }

    &-xs{
        @include font-size(11);
        @include border-radius(3px);
        padding: 4px 10px;
        border-width: 1px;
    }

    &-sm{
        @include font-size(13);
        @include border-radius(3px);
        padding: 5px 12px;
        border-width: 1px;
    }

    &-md{
        @include font-size(16);
        @include border-radius(5px);
    }

    &-lg{
        @include font-size(17);
        @include border-radius(5px);
    }

    &-xl{
        @include font-size(19);
        @include border-radius(5px);
        border-width: 2px;
    }

    @include respond-to('sm'){
        display: inline-block;
    }
}

p {
    & + p a.btn{
        margin-top: 10px;
    }
}
